<template>
  <ThePage>
    <slot />
    <Teleport to="body">
      <DebugMenu v-if="useDebug().isShowDebugButton" class="debug-menu-layout" />
    </Teleport>
  </ThePage>
</template>

<style lang="scss" scoped>
@use "assets/css/mixin" as mixin;

#layout-page {
  width: 100%;
  height: 100%;

  @include mixin.min-aspect() {
    height: auto;
  }
}

.debug-menu-layout {
  top: 0;

  @include mixin.zIndexFromLayer("overlay", 999);
}
</style>
